import ModalButton from '../../../Shared/ModalButton'

import { FormStatusEditForm } from './FormStatusEditForm'

import Button from 'src/app/components/Shared/Elements/Button'
import Icon from 'src/app/components/Shared/Icon'

const FormFieldItemAutomationModal = ({
  allFields,
  fieldItem,
  statusTemplates,
  warnings,
  handleChange,
  handleWarnings,
  disableStatusEditing
}) => {
  const statusCount =
    fieldItem.autoUpdates &&
    fieldItem.autoUpdates?.filter(
      au =>
        au.active &&
        !au.updatesStatus?.archived &&
        !au.relationFromFormQuestion?.archived
    )?.length
  return (
    <ModalButton
      renderModalActions={toggle => (
        <Button onClick={toggle}>
          {disableStatusEditing ? 'Okay' : 'Done'}
        </Button>
      )}
      modalHeader={`${fieldItem.helpText ||
        fieldItem.title ||
        "This Question's"} Pulse Connections`}
      buttonLabel={
        <>
          <Icon
            icon="Pulse"
            height={15}
            width={15}
            title="Add Automations"
            color={statusCount > 0 ? 'success' : 'text'}
          />
          {statusCount > 0 && `(${statusCount})`}
        </>
      }
      buttonProps={{
        variant: 'link',
        sx: { padding: '0px' }
      }}
    >
      {() => {
        return (
          <FormStatusEditForm
            fieldItem={fieldItem}
            allFields={allFields}
            statusTemplates={statusTemplates}
            autoUpdates={fieldItem.autoUpdates}
            warnings={warnings}
            handleChange={handleChange}
            handleWarnings={handleWarnings}
            disableAddStatus={disableStatusEditing}
          />
        )
      }}
    </ModalButton>
  )
}

export default FormFieldItemAutomationModal
