import CustomRadioButtonsInput from './CustomRadioButtonsInput'
import { Flex } from 'theme-ui'
import { Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import ModalBody from '../Shared/Elements/ModalBody'
import FormGroup from '../Shared/Elements/FormGroup'

const DemographicDataInputs = ({
  onUpdate,
  onAdd,
  onRemove,
  involvedParties = [{}],
  partyCount
}) => {
  const handleSelectChange = ({ name, value, id }) => {
    onUpdate({
      id,
      [name]: value
    })
  }

  return (
    <ModalBody>
      {involvedParties.map((iv, k) => (
        <Flex
          sx={{
            flexDirection: 'column',
            my: 10,
            bg: 'gray200',
            p: 3
          }}
        >
          <h6
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              alignItems: 'center'
            }}
          >
            {involvedParties.length === 1 && partyCount > 1
              ? 'Average Demographic'
              : `Individual ${k + 1}`}
            {involvedParties.length === 1 &&
            isEmpty(involvedParties[0]) ? (
              <Fragment />
            ) : (
              <Button variant="link" onClick={() => onRemove(iv.id)}>
                Remove
              </Button>
            )}
          </h6>
          <FormGroup>
            <Label>Gender</Label>
            <CustomRadioButtonsInput
              id="gender"
              name="gender"
              value={iv.gender}
              handleSelect={vals =>
                handleSelectChange({ ...vals, id: iv.id || k })
              }
              buttonStyles={{ fontSize: '14px' }}
              options={[
                { value: 'Male' },
                { value: 'Female' },
                { value: 'Other' }
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Label>Geographic Origin</Label>
            <CustomRadioButtonsInput
              id="geoOrigin"
              name="geoOrigin"
              value={iv.geoOrigin}
              handleSelect={vals =>
                handleSelectChange({ ...vals, id: iv.id || k })
              }
              buttonStyles={{ fontSize: '14px' }}
              options={[
                { value: 'Resident' },
                { value: 'Non-Resident' }
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="sort">Age Group</Label>
            <CustomRadioButtonsInput
              id="age"
              name="age"
              value={iv.age}
              handleSelect={vals =>
                handleSelectChange({ ...vals, id: iv.id || k })
              }
              buttonStyles={{ fontSize: '14px' }}
              options={[
                { value: '1-2' },
                { value: '3-5' },
                { value: '6-12' },
                { value: '13-17' },
                { value: '18-40' },
                { value: '41-64' },
                { value: '65+' }
              ]}
            />
          </FormGroup>
        </Flex>
      ))}

      <Button
        onClick={e => {
          onAdd()
        }}
        variant="primary"
      >
        {'Add Individual +'}
      </Button>
    </ModalBody>
  )
}

export default DemographicDataInputs
