import { useState } from 'react'

import { CenteredContent } from '../../../components'

import Spinner from 'src/images/icons/Spinner'
import {
  GET_MY_AGENCIES_FORMS,
  useGetCurrentAgencyContext
} from '../../../hooks/queries'

import { Flex, Box } from 'theme-ui'
import ModalButton from '../../../components/Shared/ModalButton'

import { useMutation, useQuery } from '@apollo/client'

import CustomSelectInput from '../../../components/Forms/CustomSelectInput'

import { Fragment } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import last from 'lodash/last'
import uniqBy from 'lodash/uniqBy'

import { useToast } from 'src/components/toasts'
import { RolesOnly } from 'src/app/services/auth'
import { theme } from 'src/styles'

import FormBuilder from './formBuilder'

import { ARCHIVE_ONE_FORM } from 'src/app/hooks/mutations'

import FormPinButton from './formBuilder/FormPinButton'
import { GenerateFormQRCode } from './GenerateQRCode'
import LabelBadge from '../../Shared/LabelBadge'
import TemplateList from './TemplateList'
import Button from '../../Shared/Elements/Button'
import ListGroup from '../../Shared/Elements/ListGroup'
import ListGroupItem from '../../Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../../Shared/Elements/ListGroupItemHeading'
import ModalBody from '../../Shared/Elements/ModalBody'
import Input from '../../Shared/Elements/Input'

import Icon from '../../Shared/Icon'
import { FormSubmitRendererModalButton } from './formSubmitRenderer/FormSubmitRendererModalButton'
import { FormResponseModalButton } from './FormResponseModalButton'
import { DuplicateFormButton } from './DuplicateFormButton'
import { Overline } from '../../Shared'
import { FormPageTypes } from 'src/app/containers/Operations/utils'
import Elipses from '../../Shared/Elipses'

function getFormWhereArgsByType(formType) {
  if (formType === 'incidentAttachedForms') {
    return {
      supervisorReviewConfig: null
    }
  }

  return formType === 'supervisor'
    ? {
        supervisorReviewConfig: {
          id: {}
        }
      }
    : formType === 'medical'
    ? {
        medicalFormConfig: {
          id: {}
        }
      }
    : {
        supervisorReviewConfig: null,
        medicalFormConfig: null
      }
}

const SearchContainer = ({
  refetch,
  loading,
  hideFilters,
  formType,
  categories
}) => {
  const [showArchived, setShowArchived] = useState(false)
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState({})

  return (
    <Flex
      sx={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
        pb: 20
      }}
      as={'form'}
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        let filters = {}

        if (filter.fileCategories) {
          filters.fileCategories = {
            some: {
              id: {
                in: filter.fileCategories.map(fc => fc.id)
              }
            }
          }
        }

        if (search) {
          filters.OR = [
            {
              name: { mode: 'insensitive', contains: search }
            },
            {
              fileCategories: {
                some: {
                  name: {
                    mode: 'insensitive',
                    contains: search
                  }
                }
              }
            }
          ]
        }

        if (showArchived) {
          filters.archived = {}
        }

        refetch({
          where: {
            ...filters,
            ...getFormWhereArgsByType(formType)
          }
        })
      }}
    >
      <Flex
        sx={{
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        <Box
          sx={{
            minWidth: '300px',
            pr: 20,
            pb: 10
          }}
        >
          <Input
            placeholder="Search by form category or name."
            onChange={e => setSearch(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            minWidth: '300px',
            pr: 20,
            pb: 10
          }}
        >
          <CustomSelectInput
            isMulti={true}
            getOptionValue={v => v.id}
            getOptionLabel={v => v.name}
            value={filter.fileCategories || []}
            placeholder="Select form categories..."
            onChange={(selected, action) => {
              setFilter(prev => ({
                ...prev,
                fileCategories: selected
              }))
            }}
            options={categories}
          />
        </Box>
        <Box
          sx={{
            pr: 20,
            display: hideFilters?.includes('showArchived')
              ? 'none'
              : 'block'
          }}
        >
          <Input
            type="checkbox"
            style={{ cursor: 'pointer' }}
            id={'showArchived'}
            name={'showArchived'}
            checked={showArchived}
            readOnly
            label={'Include Archived'}
            onClick={() => {
              setShowArchived(!showArchived)
            }}
          />
        </Box>

        <Box
          sx={{
            pr: 20,
            pb: 10
          }}
        >
          <Button variant="primary" style={{ minWidth: 100 }}>
            {loading || 'Search'}
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

const CreateFormFlow = props => {
  const [step, setStep] = useState(0)
  return (
    <ModalBody>
      {step === 0 ? (
        <Flex
          sx={{
            gap: '20px',
            mt: '20px',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Button
            variant="iconBox"
            onClick={() => {
              setStep(1)
            }}
          >
            <Icon icon="blankTemplate" height={35} width={35} />
            Create From Scratch
          </Button>
          <Button
            variant="iconBox"
            onClick={() => {
              setStep(2)
            }}
          >
            <Icon icon="operationsColored" height={35} width={35} />
            Use Template
          </Button>
        </Flex>
      ) : step === 1 ? (
        <FormBuilder
          formType={props.formType}
          onFinish={props.onFinish}
          {...props}
        />
      ) : step === 2 ? (
        <>
          <TemplateList
            onFinish={props.onFinish}
            onExit={() => setStep(0)}
            formType={props.formType}
            {...props}
          />
        </>
      ) : null}{' '}
    </ModalBody>
  )
}

const FormList = ({
  selectText,
  hideEdit,
  formType,
  onSelect,
  pinnedOnly,
  submissionId,
  take,
  hideSearch,
  emptyHeight,
  onFormSubmit,
  onFormSubmitFinish,
  filterForms,
  featuredForms,
  emptyState,
  hidePin,
  hideCreateButton,
  hideResponseModal,
  hideFilters,
  disableAutoSave,
  submitToastText,
  onDraftFinish
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const vars = {
    take: take || 10,

    where: {
      ...getFormWhereArgsByType(formType),
      ...(featuredForms &&
        featuredForms.length && {
          id: { in: featuredForms }
        }),
      ...(pinnedOnly && {
        pinned: {
          equals: true
        }
      })
    }
  }

  const {
    data,
    refetch,
    loading,
    networkStatus,
    fetchMore
  } = useQuery(GET_MY_AGENCIES_FORMS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: vars
  })

  const [archiveOneForm, { loading: updating }] = useMutation(
    ARCHIVE_ONE_FORM,
    {
      update: (store, { data: { archiveOneForm } }) => {
        // Read the data from our cache for this query.
        const data = cloneDeep(
          store.readQuery({
            query: GET_MY_AGENCIES_FORMS,
            variables: vars
          })
        )

        if (archiveOneForm.archived) {
          data.myAgenciesForms = data.myAgenciesForms.filter(
            d => d.id !== archiveOneForm.id
          )
          store.writeQuery({
            query: GET_MY_AGENCIES_FORMS,
            data: { ...data },
            variables: vars
          })
        }
      }
    }
  )

  const categories = currentAgency.fileCategories || []

  const formsFiltered = filterForms
    ? data?.myAgenciesForms?.filter(form => {
        return filterForms(form)
      })
    : data?.myAgenciesForms || []

  return (
    <Fragment>
      {!hideSearch && (
        <SearchContainer
          refetch={refetch}
          loading={loading && <Spinner white />}
          categories={categories}
          hideFilters={hideFilters}
          formType={formType}
        />
      )}
      {!hideCreateButton && (
        <Flex
          sx={{ gap: '20px', mb: '10px', justifyContent: 'flex-end' }}
        >
          <RolesOnly roles={['ADMIN']}>
            <ModalButton
              modalHeader={`Create New ${FormPageTypes[formType]?.title}`}
              buttonProps={{
                variant: 'primary'
              }}
              contentSize={'lg'}
              buttonLabel={`New ${FormPageTypes[formType]?.title} +`}
              modalProps={{
                checkOnClose: true
              }}
            >
              {({ toggle }) => {
                return (
                  <CreateFormFlow
                    onFinish={toggle}
                    formType={formType}
                  />
                )
              }}
            </ModalButton>
          </RolesOnly>
        </Flex>
      )}
      {formsFiltered?.length > 0 ? (
        <ListGroup>
          {formsFiltered.map((f, k) => (
            <FormItem
              formType={formType}
              submissionId={submissionId}
              key={k}
              onSelect={onSelect}
              categories={categories}
              currentAgency={currentAgency}
              selectText={
                featuredForms && featuredForms.includes(f.id)
                  ? 'Suggested'
                  : selectText
              }
              submitToastText={submitToastText}
              hideEdit={hideEdit}
              archiveOneForm={archiveOneForm}
              hideResponseModal={hideResponseModal}
              hidePin={hidePin}
              updating={updating}
              onFormSubmit={onFormSubmit}
              onFormSubmitFinish={onFormSubmitFinish}
              onDraftFinish={onDraftFinish}
              disableAutoSave={disableAutoSave}
              {...f}
            />
          ))}
        </ListGroup>
      ) : (
        emptyState || (
          <CenteredContent
            variant="card"
            bc="background"
            height={emptyHeight || '200px'}
          >
            {loading && <Spinner />}
            No Forms
          </CenteredContent>
        )
      )}

      {data &&
        data.myAgenciesForms.length > 0 &&
        data.myAgenciesForms.length % 10 === 0 && (
          <Flex sx={{ p: 10, width: '100%' }}>
            <Button
              variant="primary"
              sx={{ margin: 'auto' }}
              disabled={networkStatus === 3 || loading}
              icon={networkStatus === 3 || loading ? 'spinner' : ''}
              onClick={() =>
                fetchMore({
                  variables: {
                    cursor: {
                      id: data && last(data.myAgenciesForms).id
                    },
                    skip: 1
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev

                    return Object.assign({}, prev, {
                      myAgenciesForms: uniqBy(
                        [
                          ...prev.myAgenciesForms,
                          ...fetchMoreResult.myAgenciesForms
                        ],
                        function(e) {
                          return e.id
                        }
                      )
                    })
                  }
                })
              }
            >
              Load more
            </Button>
          </Flex>
        )}
    </Fragment>
  )
}

export const FormItem = ({
  onSelect,
  categories,
  currentAgency,
  selectText,
  formType,
  hideEdit,
  archiveOneForm,
  hideResponseModal,
  submissionId,
  hidePin,
  updating,
  onFormSubmit,
  onFormSubmitFinish,
  refetch,
  onClick,
  ...f
}) => {
  const { add } = useToast()
  const isArchived = f.archived
  const categoryBadges = f.fileCategories.map((c, k) => (
    <LabelBadge
      color={c.color}
      text={c.name}
      key={k}
      styles={{
        fontSize: 12,
        padding: '5px',
        marginRight: '5px'
      }}
    />
  ))

  return (
    <ListGroupItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%'
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          maxWidth: ['100%', null, null, null, '50%'],
          width: ['100%', null, null, null, 'auto']
        }}
      >
        <Flex sx={{ mb: 10, flexWrap: 'wrap', gap: '5px' }}>
          {selectText && (
            <LabelBadge
              color={theme.colors.primary}
              text={selectText}
              styles={{
                fontSize: 12,
                padding: '10px',
                marginRight: '5px'
              }}
            >
              {selectText}
            </LabelBadge>
          )}{' '}
          {f.supervisorReviewConfig && (
            <LabelBadge
              color={'#FFC107'}
              text={'Supervisor Review'}
              styles={{
                fontSize: 12,
                padding: '5px',
                marginRight: '5px'
              }}
            />
          )}{' '}
          {categoryBadges}
        </Flex>
        <Flex sx={{ flexDirection: 'column' }}>
          <FormSubmitRendererModalButton
            {...f}
            formId={f.id}
            onFinish={() => {
              if (onFormSubmitFinish) {
                onFormSubmitFinish()
              }
            }}
            onFormSubmit={onFormSubmit}
            submissionId={submissionId}
            modalButtonProps={{
              buttonLabel: (
                <ListGroupItemHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px'
                  }}
                >
                  <Elipses mw={'400px'}>{f.name}</Elipses>
                  <Box
                    sx={{
                      minWidth: '20px'
                    }}
                  >
                    <Icon
                      icon="submit"
                      color="text"
                      width={20}
                      height={18}
                    />
                  </Box>
                </ListGroupItemHeading>
              ),
              buttonProps: {
                variant: 'naked',
                style: {
                  padding: '0px',
                  cursor: 'pointer',
                  textAlign: 'left',
                  alignItems: 'flex-start'
                }
              }
            }}
          />
          {f.hasPulseConnections && (
            <Flex
              sx={{
                gap: '5px',
                mt: '3px',
                alignItems: 'center'
              }}
            >
              <Icon
                icon="pulse"
                color={'success'}
                width={15}
                height={15}
              />{' '}
              <Overline>Connected to Pulse</Overline>
            </Flex>
          )}
        </Flex>
      </Box>

      <Flex
        sx={{
          alignItems: 'center',
          width: ['100%', null, null, 'auto'],
          py: [2, 2, 2, 2, '0px'],
          flexWrap: 'wrap',
          gap: '10px'
        }}
      >
        <RolesOnly
          roles={['ADMIN', 'READ_ONLY', 'DISPATCHER', 'SUPERVISOR']}
        >
          {!hideResponseModal && <FormResponseModalButton form={f} />}
        </RolesOnly>

        {!hideEdit && [
          <RolesOnly key={1} roles={['ADMIN']}>
            {!hidePin && (
              <FormPinButton
                formId={f.id}
                isPinned={f.pinned}
                isPinnedToSubmitScreen={f.pinnedToSubmitScreen}
              />
            )}
          </RolesOnly>,
          <RolesOnly key={2} roles={['ADMIN']}>
            <GenerateFormQRCode id={f.id} />
          </RolesOnly>,
          <RolesOnly key={'duplicate'} roles={['ADMIN']}>
            <DuplicateFormButton
              hasPulse={f.hasPulseConnections}
              formNamme={f.name}
              formId={f.id}
            />
          </RolesOnly>,
          <RolesOnly key={3} roles={['ADMIN']}>
            <ModalButton
              buttonLabel="Edit"
              buttonProps={{
                variant: 'link'
              }}
              modalProps={{
                checkOnClose: true
              }}
              modalHeader={f.name}
              contentSize={'lg'}
            >
              {({ toggle, open }) => {
                return (
                  <ModalBody>
                    {open && (
                      <FormBuilder
                        formType={formType}
                        formId={f.id}
                        onFinish={toggle}
                      />
                    )}
                  </ModalBody>
                )
              }}
            </ModalButton>
          </RolesOnly>,
          <RolesOnly key={4} roles={['ADMIN']}>
            <ModalButton
              modalHeader={
                ' Are you sure you want to archive this form?'
              }
              buttonProps={{
                variant: 'link',
                icon: isArchived ? 'restore' : 'trash',
                iconColor: isArchived ? 'success' : 'danger',
                sx: {
                  color: isArchived ? 'success' : 'danger'
                }
              }}
              renderModalActions={toggle => (
                <Button
                  disabled={updating}
                  onClick={async e => {
                    const res = await archiveOneForm({
                      variables: {
                        id: f.id
                      }
                    })
                    if (res && !res.errors) {
                      add({
                        content: `Form ${
                          f?.archived ? 'Restore' : 'Archive'
                        }d.`,
                        color: 'success'
                      })
                    }
                    toggle(e)
                  }}
                  variant={f?.archived ? 'success' : 'danger'}
                >
                  {f?.archived
                    ? updating
                      ? 'Restoring...'
                      : 'Yes, restore'
                    : updating
                    ? 'Archving...'
                    : 'Yes, archive'}
                </Button>
              )}
            />
          </RolesOnly>
        ]}
      </Flex>
    </ListGroupItem>
  )
}

export default FormList
