/** @jsxImportSource theme-ui */

import { RichTextRender } from 'src/app/components/Shared/RichTextRender'
import Badge from 'src/app/components/Shared/Elements/Badge'
import Button from 'src/app/components/Shared/Elements/Button'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Input from 'src/app/components/Shared/Elements/Input'
import InputGroupText from 'src/app/components/Shared/Elements/InputGroupText'
import Label from 'src/app/components/Shared/Elements/Label'
import ListGroup from 'src/app/components/Shared/Elements/ListGroup'
import ListGroupItem from 'src/app/components/Shared/Elements/ListGroupItem'
import ModalFooter from 'src/app/components/Shared/Elements/ModalFooter'

import { useQuery } from '@apollo/client'
import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'
import ModalButton from 'src/app/components/Shared/ModalButton'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import intersection from 'lodash/intersection'
import uniqueId from 'lodash/uniqueId'

import { Box, Flex } from 'theme-ui'

import Spinner from 'src/images/icons/Spinner'
import MatchFieldItemAllowedToStatusTemplate from '../MatchFieldItemAllowedToStatusTemplate'
import Icon from 'src/app/components/Shared/Icon'

import PayWallScreen from 'src/app/components/PayWalls/PayWallScreen'
import {
  GET_STATUS_TEMPLATES,
  GET_STATUS_TEMPLATES_CATEGORIES
} from 'src/app/hooks/queries'
import { useState } from 'react'
import { CenteredContent } from 'src/app/components'
import { UpdatedByTooltip } from 'src/app/components/Statuses/StatusTemplateAutomationTooltip'
import { StatusTemplateForm } from 'src/app/components/Statuses/StatusTemplateForm'
import { fieldItemConfig } from './dataMapping'
import { useToast } from 'src/components/toasts'
import InlineTooltip from 'src/app/components/Shared/InlineTooltip'
import { Overline } from 'src/app/components/Shared'
import stripHtml from 'src/utils/stripHtml'

export const FormStatusEditForm = ({
  autoUpdates = [],
  fieldItem,
  allFields,
  disableAddStatus,
  handleChange
}) => {
  const {
    data: statusTemplateCategories,
    loading: statusTemplateCategoriesLoading
  } = useQuery(GET_STATUS_TEMPLATES_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  })
  const { data, loading } = useQuery(GET_STATUS_TEMPLATES, {
    variables: {
      where: {
        archived: {
          equals: false
        }
      }
    }
  })
  const activeFields = allFields
    ?.filter(f => !f?.archived)
    .map(f => ({
      ...f,
      fieldItems: f?.fieldItems?.filter(
        fi => fi.checked && !fi?.archived
      )
    }))
  const [showInactive, setShowInactive] = useState(false)
  const aus = autoUpdates.filter(au =>
    showInactive ? true : au.active
  )

  const hasInactive = autoUpdates.some(au => !au.active)

  const handleAddAutomation = newAutomation => {
    handleChange({
      name: 'autoUpdates',
      value: [
        ...autoUpdates,
        {
          ...newAutomation,
          id: uniqueId(),
          newAutomation: true
        }
      ]
    })

    if (newAutomation?.allowedValues) {
      handleChange({
        value: newAutomation?.allowedValues?.map(av => av?.value),
        name: 'allowedValues'
      })
    }
  }

  const CreateStatusButton = (
    <Flex
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        mt: '25px',
        flexDirection: 'column'
      }}
    >
      <Flex sx={{ gap: '5px', alignItems: 'center' }}>
        <ModalButton
          buttonProps={{
            disabled: disableAddStatus
          }}
          modalHeader={`Connect statuses to "${fieldItem?.helpText ||
            fieldItem?.title}"`}
          buttonLabel="Connect Existing +"
        >
          {({ toggle }) => (
            <CreateStatusAutomationModal
              onFinish={toggle}
              allFields={activeFields}
              autoUpdates={autoUpdates}
              fieldItem={fieldItem}
              handleChange={handleChange}
              statusTemplates={data?.myAgenciesStatusTemplates}
              addAutomation={a => {
                handleAddAutomation(a)
                toggle()
              }}
            />
          )}
        </ModalButton>
        or
        <ModalButton
          buttonProps={{
            disabled: disableAddStatus
          }}
          modalHeader={`${fieldItem?.helpText ||
            fieldItem?.title} Statuses`}
          buttonLabel="Create Status +"
        >
          {({ toggle }) => (
            <CreateStatusFromFormFieldModalForm
              statusTemplateCategories={
                statusTemplateCategories?.myAgenciesStatusTemplateCategories
              }
              onCancel={toggle}
              allFields={activeFields}
              autoUpdates={autoUpdates}
              fieldItem={fieldItem}
              handleChange={handleChange}
              addAutomation={a => {
                handleAddAutomation(a)
                toggle()
              }}
            />
          )}
        </ModalButton>
      </Flex>
      {disableAddStatus && (
        <span sx={{ color: 'gray600' }}>
          Looks like you have changed the form structure. Please save
          form before connecting statuses..
        </span>
      )}
    </Flex>
  )

  return loading || statusTemplateCategoriesLoading ? (
    <Spinner centered containerHeight={200} />
  ) : (
    <PayWallScreen featureLabel={'statuses'}>
      <Flex sx={{ width: '100%', justifyContent: 'flex-end' }}>
        {hasInactive && (
          <Button
            onClick={() => setShowInactive(!showInactive)}
            variant="link"
          >
            {showInactive ? 'Hide inactive' : 'Show inactive'}
          </Button>
        )}
      </Flex>
      <ListGroup>
        {aus?.length === 0 ? (
          <CenteredContent height={'200px'}>
            No statuses connected to this field yet.
            {CreateStatusButton}
          </CenteredContent>
        ) : (
          <>
            {aus.map((sa, index) => {
              const archivedRelationFromFormQuestion =
                sa.relationFromFormQuestion?.archived ||
                sa.relationFromFormQuestion?.formField?.archived
              return (
                <>
                  <ListGroupItem key={index}>
                    <Flex
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap'
                      }}
                    >
                      <Flex
                        sx={{
                          alignItems: 'flex-start',
                          flex: 1,
                          flexWrap: 'wrap'
                        }}
                      >
                        <Box
                          sx={{
                            flex: 1,
                            alignItems: 'center',
                            gap: '10px'
                          }}
                        >
                          <Overline sx={{ mb: '8px' }}>
                            Pulse Updated:
                          </Overline>
                          <Badge
                            variant={
                              sa.updatesStatus.archived
                                ? 'danger'
                                : 'success'
                            }
                          >
                            {sa.updatesStatus.title}{' '}
                            {sa.updatesStatus.archived &&
                              '(Archived)'}
                          </Badge>
                        </Box>

                        <Box
                          sx={{
                            flex: 1,
                            alignItems: 'center',
                            gap: '10px'
                          }}
                        >
                          <Overline sx={{ mb: '8px' }}>
                            For {sa.relationFromFormQuestion?.type}{' '}
                            Selected in:
                          </Overline>
                          <Badge
                            variant={
                              archivedRelationFromFormQuestion
                                ? 'danger'
                                : 'success'
                            }
                          >
                            {stripHtml(
                              sa.relationFromFormQuestion.helpText ||
                                sa.relationFromFormQuestion?.title ||
                                sa.relationFromFormQuestion?.type
                            )}{' '}
                            {archivedRelationFromFormQuestion &&
                              '(Archived)'}
                          </Badge>
                        </Box>
                      </Flex>

                      <ToggleStatusSwitch
                        disabled={autoUpdates?.find(
                          au =>
                            au.updatesStatus.id ===
                              sa.updatesStatus.id &&
                            au.active &&
                            !sa.active
                        )}
                        toggleStatusAutomation={() =>
                          handleChange({
                            name: 'autoUpdates',
                            value: autoUpdates.map((au, k) =>
                              au?.id === sa?.id
                                ? { ...au, active: !au.active }
                                : au
                            )
                          })
                        }
                        active={sa.active}
                      />
                    </Flex>
                  </ListGroupItem>
                </>
              )
            })}
            {CreateStatusButton}
          </>
        )}
      </ListGroup>
    </PayWallScreen>
  )
}

const CreateStatusAutomationModal = ({
  autoUpdates,
  onFinish,
  allFields,
  fieldItem,
  addAutomation,
  handleChange,
  statusTemplates
}) => {
  const { add } = useToast()
  const [values, setValues] = useState({})

  const relationFromFormQuestionOptions =
    values['updatesStatus'] &&
    allFields.map((f, k) => ({
      label: (
        <RichTextRender mw={'120px'} maxLines={1} text={f.title} />
      ),
      options: f.fieldItems
        ?.filter(f => !f?.archived)
        .map((fi, key) => {
          return {
            ...fi,
            label: `Question ${key + 1} (${fi.valueTypeFormat ||
              fi.type})`,
            value: fi
          }
        })
        .filter(fi => {
          return compareFieldItemTypeToStatusOnNodeType(
            fi.type,
            values['updatesStatus'].statusOnNodeType
          )
        })
    }))

  const relationQuestionsWithOptions =
    relationFromFormQuestionOptions &&
    flatMap(
      relationFromFormQuestionOptions
        .filter(v => v.options.length > 0)
        .map(v => v.options)
    )

  const hasOneRelationOption =
    relationQuestionsWithOptions?.length === 1

  async function onAddAutomation() {
    const relationFromFormQuestion = hasOneRelationOption
      ? relationQuestionsWithOptions[0]
      : values.relationFromFormQuestion

    if (!values['updatesStatus']) {
      add({
        content: 'Please select a status to update',
        color: 'danger'
      })
      return
    }

    if (!relationFromFormQuestion) {
      add({
        content:
          'Please select a question for the resource, location, user or asset being updated.',
        color: 'danger'
      })
      return
    }

    await addAutomation({
      active: true,
      updatesStatus: values.updatesStatus,
      relationFromFormQuestion
    })

    onFinish && onFinish()
  }

  const statusAllowedValues =
    values?.['updatesStatus']?.allowedValues?.length > 0 &&
    values?.['updatesStatus']?.allowedValues?.map(av => av.value)

  const onlyCertainGroups =
    values?.['updatesStatus']?.onlyForGroups?.length > 0 &&
    values?.['updatesStatus']?.onlyForGroups

  const onlyCertainGroupsId =
    onlyCertainGroups && onlyCertainGroups?.map(av => av.id)

  const questionAllowedValues =
    fieldItem?.allowedValues?.length > 0 && fieldItem?.allowedValues

  const answersDontMatch =
    intersection(statusAllowedValues, questionAllowedValues)
      .length !== statusAllowedValues.length

  const nodesDontMatch =
    intersection(onlyCertainGroupsId, questionAllowedValues)
      .length !== onlyCertainGroupsId.length

  const prettyType = `${values.updatesStatus?.statusOnNodeType?.toLowerCase()}s`

  const statusOptions = Object.entries(
    groupBy(
      statusTemplates
        .filter(st => st.type === fieldItem.type?.toUpperCase())
        .filter(st =>
          allFields.some(f =>
            f.fieldItems
              ?.filter(f => !f?.archived)
              .some(fi => {
                return compareFieldItemTypeToStatusOnNodeType(
                  fi.type,
                  st.statusOnNodeType
                )
              })
          )
        ),
      'statusOnNodeType'
    )
  ).map(function([key, items]) {
    return {
      label: `${key} STATUSES`,
      options: items.map(i => {
        const automationExists = autoUpdates.some(
          sa => sa.updatesStatus.id === i.id && sa.active
        )
        return {
          ...i,
          title: `${i.title} ${
            automationExists ? '(Connected)' : ''
          }`,
          isDisabled: automationExists
        }
      })
    }
  })

  return (
    <Box>
      {statusOptions?.length > 0 ? (
        <FormGroup>
          <Label
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            The status you would like to connect to this questions
            {values['updatesStatus'] && (
              <UpdatedByTooltip
                id={values['updatesStatus']?.id}
                autoUpdates={values['updatesStatus']?.updatedBy}
              />
            )}
          </Label>

          <CustomSelectInput
            id={'updatesStatus'}
            name={'updatesStatus'}
            getOptionLabel={opt => opt.title}
            getOptionValue={opt => opt.id}
            options={statusOptions}
            value={values['updatesStatus']}
            onChange={sel => {
              setValues({
                ...values,
                valueFromFormQuestion: null,
                relationFromFormQuestion: null,
                updatesStatus: sel
              })
            }}
          />

          {statusAllowedValues && (
            <InputGroupText
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: answersDontMatch ? 'danger' : 'success'
              }}
            >
              <Flex
                sx={{
                  alignItems: 'center',
                  gap: '4px',
                  textAlign: 'left',
                  flex: 1
                }}
              >
                <Icon
                  height={25}
                  width={25}
                  color="white"
                  sx={{
                    bg: answersDontMatch ? 'danger' : 'success',
                    borderRadius: '20px',
                    mr: '10px',
                    p: '4px',
                    minWidth: 25
                  }}
                  icon={answersDontMatch ? 'close' : 'checkmark'}
                />
                {answersDontMatch
                  ? `This status expects ${statusAllowedValues.join(
                      ', '
                    )} but
              question allows ${
                questionAllowedValues
                  ? questionAllowedValues?.join(', ')
                  : 'any answer'
              }`
                  : `Question and status match! Both expect: ${statusAllowedValues.join(
                      ', '
                    )}`}
              </Flex>
              <Box>
                {answersDontMatch && (
                  <MatchFieldItemAllowedToStatusTemplate
                    currentOptions={questionAllowedValues}
                    newOptions={statusAllowedValues}
                    fieldItem={fieldItem}
                    statusTemplate={values['updatesStatus']}
                    onSync={values => {
                      handleChange({
                        value: values,
                        name: 'allowedValues'
                      })
                    }}
                  />
                )}
              </Box>
            </InputGroupText>
          )}
        </FormGroup>
      ) : (
        <InlineTooltip
          variant="warning"
          tip={`Currently you have no ${
            fieldItem?.type
          } statuses that update ${allFields
            ?.flatMap(f => [...f?.fieldItems])
            ?.filter(f => {
              return f?.isNodeType
            })
            ?.map(rf => rf?.title + 's')
            .join(', ')
            ?.replace(/, ([^,]*)$/, ' or $1')}`}
        />
      )}

      {values['updatesStatus'] && (
        <FormGroup>
          <Label>{`For the ${prettyType} selected in this question.`}</Label>

          {hasOneRelationOption ? (
            <Box
              sx={{
                fontWeight: 'bold',
                fontStyle: 'italic',
                my: '15px'
              }}
            >
              {relationQuestionsWithOptions[0]?.label}
            </Box>
          ) : (
            <CustomSelectInput
              id={'relationFromFormQuestion'}
              name={'relationFromFormQuestion'}
              options={relationFromFormQuestionOptions}
              value={values['relationFromFormQuestion']}
              onChange={sel => {
                setValues({
                  ...values,
                  relationFromFormQuestion: sel
                })
              }}
            />
          )}
          {nodesDontMatch && (
            <InputGroupText
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: nodesDontMatch ? 'yellow' : 'success'
              }}
            >
              The {prettyType} available in this question may not
              match the groups expected in the status.
            </InputGroupText>
          )}
        </FormGroup>
      )}

      <ModalFooter style={{ marginTop: '30px' }} onClose={onFinish}>
        <Button variant="primary" onClick={() => onAddAutomation()}>
          Add Automation
        </Button>
      </ModalFooter>
    </Box>
  )
}

const CreateStatusFromFormFieldModalForm = ({
  fieldItem,
  statusTemplateCategories,
  addAutomation,
  onCancel,
  allFields
}) => {
  const relationFromFormQuestionOptions = allFields.map((f, k) => ({
    label: (
      <RichTextRender mw={'120px'} maxLines={1} text={f.title} />
    ),
    options: f.fieldItems
      ?.filter(f => !f?.archived)
      .map((fi, key) => {
        return {
          ...fi,
          label:
            fi.helpText ||
            `Question ${key + 1} (${fi.valueTypeFormat || fi.type})`,
          value: fi
        }
      })
      .filter(fi => {
        return fieldItemConfig?.find(v => v?.value === fi.type)
          ?.isNodeType
      })
  }))

  const relationQuestionsWithOptions = flatMap(
    relationFromFormQuestionOptions
      .filter(v => v.options.length > 0)
      .map(v => v.options)
  )

  const hasOneRelationOption =
    relationQuestionsWithOptions?.length === 1 &&
    relationQuestionsWithOptions[0]

  const [values, setValues] = useState({
    relationFromFormQuestion: hasOneRelationOption,
    statusOnNodeType:
      hasOneRelationOption?.type !== 'positions' &&
      hasOneRelationOption?.type
  })

  async function handleFinish(res) {
    const relationFromFormQuestion = values.relationFromFormQuestion

    await addAutomation({
      ...res,
      active: true,
      updatesStatus: res,
      relationFromFormQuestion
    })
  }

  return values?.statusOnNodeType ? (
    <StatusTemplateForm
      nodeType={values?.statusOnNodeType}
      type={fieldItem?.type}
      title={fieldItem?.helpText}
      defaultAllowedValues={fieldItem?.allowedValues}
      onSuccess={handleFinish}
      onFinish={onCancel}
      categories={statusTemplateCategories}
    />
  ) : (
    <>
      {values?.hasOneRelationOption === 'positions' ||
      values?.relationFromFormQuestion?.type === 'positions' ? (
        <>
          <h6>What type of position will this status affect?</h6>
          <Input
            id="resource"
            name="resource"
            type="checkbox"
            value={values?.statusOnNodeType === 'resource'}
            checked={values?.statusOnNodeType === 'resource'}
            label="Resources (units)"
            onChange={e => {
              setValues({
                ...values,
                statusOnNodeType: 'resource'
              })
            }}
          />

          <Input
            id="location"
            name="location"
            type="checkbox"
            value={values?.statusOnNodeType === 'location'}
            checked={values?.statusOnNodeType === 'location'}
            label="Locations"
            onChange={e => {
              setValues({
                ...values,
                statusOnNodeType: 'location'
              })
            }}
          />
        </>
      ) : (
        <>
          <h6>
            Which question will supply the user, resource, location or
            asset to be updated?
          </h6>
          <CustomSelectInput
            id={'relationFromFormQuestion'}
            name={'relationFromFormQuestion'}
            options={relationFromFormQuestionOptions}
            value={values['relationFromFormQuestion']}
            onChange={sel => {
              setValues({
                ...values,
                statusOnNodeType:
                  sel?.type === 'positions' ? null : sel?.type,
                relationFromFormQuestion: sel
              })
            }}
          />
        </>
      )}
    </>
  )
}

const ToggleStatusSwitch = ({
  toggleStatusAutomation,
  disabled,
  active
}) => {
  return (
    <Button
      id={`active`}
      name="active"
      icon={active ? 'trash' : 'Restore'}
      variant={active ? 'linkDanger' : 'linkSuccess'}
      onClick={toggleStatusAutomation}
      disabled={disabled}
    />
  )
}

function mapStatusTypeToFieldTypes(statusOnNodeType) {
  if (
    statusOnNodeType === 'LOCATION' ||
    statusOnNodeType === 'RESOURCE'
  ) {
    return 'positions'
  }

  if (statusOnNodeType === 'USER' || statusOnNodeType === 'ASSET') {
    return `${statusOnNodeType.toLowerCase()}s`
  }

  return statusOnNodeType.toLowerCase()
}

function compareFieldItemTypeToStatusOnNodeType(
  fieldItemType,
  statusOnNodeType
) {
  return statusOnNodeType
    ? fieldItemType?.toLowerCase() ===
        mapStatusTypeToFieldTypes(statusOnNodeType)
    : fieldItemType
}
